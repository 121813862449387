.nav-tabs {
	.nav-link {
		background: $nav-tabs-link-bg;
		color: $nav-tabs-link-color;
		border-radius: 0;
		border: 1px solid $border-color;
		padding: 0.562rem 1.25rem;
		@media (max-width: 767px) {
			padding: 0.562rem 1.09rem;
		}
	}
	.nav-item {
		&:first-child {
			.nav-link {
				border-radius: 4px 0 0 0;
			}	
		}
		&:last-child {
			.nav-link {
				border-radius: 0 4px 0 0;
			}
		}
	}
	&.nav-tabs-vertical {
		@extend .flex-column;
		border-bottom: 0;
		.nav-link {
			&.active {
				border-bottom-color: $border-color;
			}
		}
		.nav-item {
			&:first-child {
				.nav-link {
					border-radius: 4px 4px 0 0;
				}	
			}
			&:last-child {
				.nav-link {
					border-radius: 0 0 4px 4px;
				}
			}
		}
	}
	&.nav-tabs-vertical-custom {
		@extend .nav-tabs-vertical;
		background: theme-color(primary);
		border-radius: 4px;
		.nav-link {
			background: transparent;
			border-color: rgba($white, .2);
			color: darken(theme-color(primary), 30%);
			font-weight: 600;
			padding: .75rem 1.2rem;
			&.active {
				color: $white;
				border-color: rgba($white, .2);
			}
		}
	}
}

.nav-pills {
	border-bottom: 1px solid $border-color;
	padding-bottom: 1rem;
	font-weight: 500;
	.nav-link {
		padding: 8px 30px;
		background: #e3e7ed;
		margin-right: 3px;
		color: #596882;
		@media (max-width: 767px) {
			padding: .5rem .5rem;
		}
	}
	.nav-item {
		margin-right: 1rem;
		@media (max-width: 767px) {
			margin-right: .5rem;
		}
	}
	&.nav-pills-vertical {
		@extend .flex-column;
		border-bottom: 0;
		.nav-item {
			margin-right: 0;
			margin-bottom: 1rem;
		}
	}
	&.nav-pills-custom {
		border-bottom: 0;
		.nav-link {
			border-radius: 20px;
			padding: .5rem 1.7rem;
			background: $nav-pills-custom-bg;
			color: $black;
			&.active {
				background: theme-color(danger);
				color: $white;
			}
		}
		.nav-item {
			margin-top: .875rem;
		}
	}
}

/* Pills color variations */
@each $color, $value in $theme-colors {
  .nav-pills-#{$color} {
    .nav-link {
			color: $value;
			&.active {
				background: theme-color($color);
			}
		}
  }
}


.tab-content {
	border: 1px solid $border-color;
	border-top: 0;
	padding: 2rem 1rem;
	text-align: justify;
	&.tab-content-vertical {
		border-top: 1px solid $border-color;
	}
	&.tab-content-vertical-custom {
		border: 0;
		padding-top: 0;
	}
	&.tab-content-custom-pill {
		border: 0;
		padding-left: 0;
	}
}