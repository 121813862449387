////////// SIDEBAR ////////
$sidebar-width-lg: 240px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

$sidebar-padding: .6rem 1.25rem;
$sidebar-light-bg: #ffffff;
$sidebar-light-menu-color: #1c273c;
$sidebar-light-submenu-color: #031b4e;
$sidebar-light-menu-active-bg: initial;
$sidebar-light-menu-active-color: theme-color(primary);
$sidebar-light-menu-hover-bg: transparent;
$sidebar-light-menu-hover-color: theme-color(primary);
$sidebar-light-submenu-hover-color: theme-color(primary);
$sidebar-light-submenu-active-color: theme-color(primary);
$sidebar-light-menu-icon-color: #1c273c;
$sidebar-light-menu-arrow-color: #cdd4e0;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;

$sidebar-dark-bg: #1e1e2f;
$sidebar-dark-menu-color: #8c909a;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: theme-color(primary);
$sidebar-dark-menu-hover-bg: $sidebar-dark-bg;
$sidebar-dark-menu-hover-color: theme-color(primary);
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: $content-bg;
$sidebar-dark-submenu-hover-color: theme-color(primary);
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-profile-name-color: #ffffff;
$sidebar-dark-profile-title-color: #8d9498;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding: 0;
$sidebar-menu-height: 2.5rem;

$sidebar-submenu-padding: .25rem 0 .5rem 1.9rem;
$sidebar-submenu-font-size: 0.812rem;
$sidebar-submenu-item-padding: 0;
$sidebar-submenu-item-height: 2rem;

$sidebar-icon-font-size:  1.25rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$sidebar-box-shadow: none;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: $sidebar-light-bg;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #f0f2f7;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: $white;
$navbar-height: 64px;
$navbar-menu-color: #1c273c;
$navbar-font-size: $default-font-size;
$navbar-icon-font-size: .9375rem;

///////// NAVBAR ////////