/* Colorpicker */

.asColorPicker-dropdown {
  max-width: initial;
}
.asColorPicker-trigger{
    height: auto;
    width: auto;
    border: 0;
    span {
      border-radius: 0;
      width: 35px;
    }
}

.asColorPicker-input {
  @extend .form-control;
}

.asColorPicker-wrap {
  @extend .input-group;
}
