/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}
.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}
.grid-margin-sm {
  @media (min-width: 576px) {
  margin-bottom: $card-spacing-y;
  }
}
.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
.grid-margin-md {
  @media (min-width: 768px) {
    margin-bottom: $card-spacing-y; 
  }
}
.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}
.grid-margin-lg {
  @media (min-width: 992px) {
    margin-bottom: $card-spacing-y; 
  }
}
.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}
.grid-margin-xl {
  @media (min-width: 1200px) {
    margin-bottom: $card-spacing-y; 
  }
}
.img-lg {
  width: 92px;
  height: 92px;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.img-xs {
  width: 37px;
  height: 37px;
}
.img-ss {
  width: 26px;
  height: 26px;
}
.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);
  >.card{
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}
.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}
.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}
.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}
.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;
}

.flex-grow {
  flex-grow: 1;
}

.tx-10 { font-size: 10px; }
.tx-11 { font-size: 11px; }
.tx-12 { font-size: 12px; }
.tx-13 { font-size: 13px; }


@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

.dashed-divider {
  width: 100%;
  height: 1px;
  border-top: 1px dashed $border-color;
}

.cursor-pointer {
  cursor: pointer;
}