/* Typography */

body {
  font-size: $default-font-size;
  font-family: $type1;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  line-height: 1;
}
p {
  font-size: $default-font-size;
  margin-bottom: .5rem;
  line-height: 1.3rem;
}
h1,
.h1 {
  font-size: 2.1875rem;
}
h2,
.h2 {
  font-size: 1.75rem;
}
h3,
.h3 {
  font-size: 1.53125rem;
}
h4,
.h4 {
  font-size: 1.225rem;
}
h5,
.h5 {
  font-size: 1.09375rem;
}
h6,
.h6 {
  font-size: 0.875rem;
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
.display-1 {
  font-size: 3.75rem;
  @media (max-width: 991px) {
    font-size: 3rem;
  }
}
.display-2 {
  font-size: 3.125rem;
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
}
.display-3 {
  font-size: 2.5rem;
  @media (max-width: 991px) {
    font-size: 2rem;
  }
}
.display-4 {
  font-size: 1.875rem;
  @media (max-width: 991px) {
    font-size: 1.5rem;
  }
}
.display-5 {
  font-size: 1.25rem;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}
.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}

//blockqoute color variations
@each $color, $value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}
.error-page {
  h1 {
    font-size: 12rem;
    @media (max-width: 991px) {
      font-size: 8rem;
    }
  }
}

// Social Color
@each $color, $value in $social-colors {
  .text-#{$color} {
    color: social-color($color);
  }
}

// Page header
.page-header {
  margin-bottom: 2.5rem;
  .page-title {
    margin-bottom: 0;
    line-height: .85;
  }
}

.font-weight-medium {
  font-weight: 500;
}
.font-weight-black {
  font-weight: 900;
}