/* Bootstrap Progress */

.progress {
  @include border-radius(0);
  height: 10px;
  .progress-bar {
    @include border-radius(0);
  }

  &.progress-sm{
    height: 0.312rem;
  }
  &.progress-md {
    height: 8px;
  }
  &.progress-lg {
    height: 15px;
  }
  &.progress-xl {
    height: 18px;
  }
}